/** @jsx jsx */
import { jsx } from 'theme-ui';
// import axios from 'axios';
// import fileDownload from 'js-file-download';
import React from 'react';
import { useQueryParam, useTranslate } from '@modules';
import { Layout } from '@core';

// const handleDownload = (url:string, filename: string) => {
//   axios.get(url, {
//     responseType: 'blob',
//   })
//     .then((res) => {
//       fileDownload(res.data as any, filename);
//     });
// };

export default () => {
  const [url] = useQueryParam('url');
  const __ = useTranslate();

  React.useEffect(() => {
    // if (url) handleDownload(url, 'download.pdf');
  }, [url]);

  return <Layout>
    {url && (
      <div>
        {__('The requested file is available here')}:<br />
        <a href={url}>{url}</a>
      </div>
    )}
  </Layout>;
};
